import { useState } from 'react';
import { Box, CircularProgress, Fade, IconButton, styled } from '@mui/material';
import { ContextStatus, UpdateStatus } from '../../@types/componentLifecycle';
import Image from 'components/Image';
import Delete from '@mui/icons-material/Delete';

const AvatarContainer = styled(Box)({
  position: 'relative',
  width: '100%'
});

export default function SingleImage({
  status,
  updateStatus,
  context,
  picture,
  handleDeletePicture
}: {
  status?: ContextStatus;
  updateStatus: UpdateStatus;
  context: string;
  picture: string;
  handleDeletePicture: (
    pictureUrl?: string,
    customUpdateStatus?: UpdateStatus,
    customContext?: string
  ) => void;
}) {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  return (
    <AvatarContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Image
        src={picture}
        alt="User"
        ratio="1/1"
        style={{
          maxHeight: '343px',
          width: '100%',
          maxWidth: '343px',
          borderRadius: '16px',
          ...(isHovered ? { opacity: 0.3 } : {})
        }}
      />
      <Fade in={isHovered}>
        <IconButton
          disabled={status?.status === 'LOADING'}
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
          }}
          size="large"
          color="primary"
          onClick={() => handleDeletePicture(picture, updateStatus, context)}
        >
          {status?.status === 'LOADING' ? (
            <CircularProgress thickness={1.6} />
          ) : (
            <Delete fontSize="large" />
          )}
        </IconButton>
      </Fade>
    </AvatarContainer>
  );
}
