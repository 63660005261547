import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { Add, Close as CloseIcon, ContentPaste } from '@mui/icons-material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { DetailsItem } from './RestaurantDetailView';
import { useCallback } from 'react';

export default function OrganizationStructuredNoteJobRoles() {
  const { register, control, watch } = useFormContext();

  const {
    fields: jobRoles,
    remove,
    append
  } = useFieldArray({
    control,
    name: 'job_roles'
  });

  const roles = watch('job_roles');

  const duplicateJobRole = useCallback(
    (index: number) => {
      append(roles[index]);
    },
    [roles, append]
  );

  return (
    <Grid item container spacing={2}>
      {jobRoles?.map((jobRole, index) => {
        return (
          <Grid item container key={jobRole.key} spacing={2}>
            <Grid item container xs={12} justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1">{index + 1}</Typography>
              <Stack direction="row" spacing={2}>
                <Button
                  startIcon={<ContentPaste />}
                  onClick={() => duplicateJobRole(index)}
                  variant="outlined"
                >
                  Duplica
                </Button>
                <IconButton onClick={() => remove(index)}>
                  <CloseIcon color="error" />
                </IconButton>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register(`job_roles.${index}.occupation`)}
                fullWidth
                label="Figura ricercata"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register(`job_roles.${index}.number_of_people_required`)}
                type="number"
                fullWidth
                label="Numero Figure ricercate"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register(`job_roles.${index}.contract_and_working_hours`)}
                fullWidth
                label="Contratto e monte ore"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register(`job_roles.${index}.salary_range`)}
                fullWidth
                label="Range di stipendio"
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={`job_roles.${index}.seasonal`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Stack direction="row">
                    <FormControlLabel
                      sx={{ justifyContent: 'space-between', width: '100%', ml: 1 }}
                      control={
                        <Switch
                          id="jr_seasonal"
                          checked={(value || false) as boolean}
                          onChange={(e) => {
                            onChange(e.target.checked);
                          }}
                          color="primary"
                        />
                      }
                      label={<DetailsItem>Stagionale</DetailsItem>}
                      labelPlacement="start"
                    />
                  </Stack>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={`job_roles.${index}.accommodation`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Stack direction="row">
                    <FormControlLabel
                      sx={{ justifyContent: 'space-between', width: '100%', ml: 1 }}
                      control={
                        <Switch
                          id="jr_accommodation"
                          checked={(value || false) as boolean}
                          onChange={(e) => {
                            onChange(e.target.checked);
                          }}
                          color="primary"
                        />
                      }
                      label={<DetailsItem>Allogio</DetailsItem>}
                      labelPlacement="start"
                    />
                  </Stack>
                )}
              />
            </Grid>
          </Grid>
        );
      })}
      <Grid item container justifyContent="end">
        <Button onClick={() => append({})} startIcon={<Add />} variant="contained">
          Aggiungi ruolo
        </Button>
      </Grid>
    </Grid>
  );
}
