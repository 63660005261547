import { Grid, GridProps } from '@mui/material';
import { Status, UpdateStatus } from '../../@types/componentLifecycle';
import { Picture } from '@restworld/utility-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SingleImage } from '.';

interface Props {
  image: Picture;
  gridItemProps?: GridProps;
  status?: Status;
  updateStatus: UpdateStatus;
  handleDeletePicture: (
    pictureUrl?: string,
    customUpdateStatus?: UpdateStatus,
    customContext?: string
  ) => void;
}

export default function DraggableImage({
  image,
  gridItemProps,
  status,
  updateStatus,
  handleDeletePicture
}: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: image.url
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <Grid item {...gridItemProps} ref={setNodeRef} {...listeners} {...attributes} style={style}>
      <SingleImage
        status={status?.[image.url]}
        updateStatus={updateStatus}
        context={image.url}
        picture={image.url}
        handleDeletePicture={handleDeletePicture}
      />
    </Grid>
  );
}
