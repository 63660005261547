import { useRWMemberService } from 'service';
import { RWMember } from '@restworld/utility-types';
import { NoPicture } from 'components/picture';
import useComponentStatus from 'hooks/useComponentStatus';
import { useCallback } from 'react';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Delete } from '@mui/icons-material';
import { useRWMembers } from './TeamMatesTable';

interface Props {
  teamMate: RWMember & { picture: string };
}

const componentStatusOptions = {
  uploadPicture: { showNotificationOnSuccess: true },
  deletePicture: { showNotificationOnSuccess: true }
};

export default function TeamMatePicture({ teamMate }: Props) {
  const { refetch } = useRWMembers();

  const rwMemberService = useRWMemberService();
  const { status, updateStatus } = useComponentStatus(
    ['uploadPicture', 'deletePicture'],
    componentStatusOptions
  );

  const handleUploadPicture = useCallback(
    (picture: File) => {
      updateStatus('uploadPicture', 'LOADING');
      rwMemberService
        .uploadPicture(teamMate.user_id, picture)
        .then(() => {
          updateStatus('uploadPicture', 'SUCCESS');
          refetch();
        })
        .catch(() => {
          updateStatus('uploadPicture', 'ERROR');
        });
    },
    [rwMemberService, teamMate.user_id, updateStatus, refetch]
  );

  const handleDeletePicture = useCallback(() => {
    updateStatus('deletePicture', 'LOADING');
    rwMemberService
      .deletePicture(teamMate.user_id)
      .then(() => {
        updateStatus('deletePicture', 'SUCCESS');
        refetch();
      })
      .catch(() => {
        updateStatus('deletePicture', 'ERROR');
      });
  }, [rwMemberService, teamMate.user_id, updateStatus, refetch]);

  if (!teamMate.icon_url)
    return (
      <Box sx={{ maxWidth: '20%', mb: 2 }}>
        <NoPicture
          status={status?.uploadPicture}
          entityId={teamMate.user_id}
          handleUpload={(e) => handleUploadPicture(e.target.files[0])}
        />
      </Box>
    );
  return (
    <Box sx={{ mb: 2 }}>
      <img
        src={teamMate.icon_url}
        alt={teamMate.name}
        style={{
          width: '100px',
          height: '100px',
          objectFit: 'cover',
          borderRadius: '50%'
        }}
      />
      <LoadingButton
        onClick={handleDeletePicture}
        loading={status?.delete_picture?.status === 'LOADING'}
        startIcon={<Delete />}
      >
        Elimina foto
      </LoadingButton>
    </Box>
  );
}
