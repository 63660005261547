import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Avatar, Backdrop, Chip, CircularProgress } from '@mui/material';
import { Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import { useQuery } from '@tanstack/react-query';
import { atom, useAtom, useAtomValue } from 'jotai';

import { useRWMemberService } from 'service';
import { RWMember, RWMemberUpdatePayloadType } from 'service/rw_members';
import { SidebarContext } from 'contexts/SidebarContext';
import { SIDEBAR_FILTERS_POSITION, SIDEBAR_FILTERS_WIDTH } from 'config';

import TeamMatesOptions, { showDialogAtom, TeamMatesOptionMenu } from './TeamMatesOptions';
import useAuth from 'hooks/useAuth';
import { LoadingButton } from '@mui/lab';
import TeamMatesEditFormDialog from './TeamMatesEditForm';
import TeamMatesDeleteConfirmationDialog from './TeamMatesDeleteConfirmationDialog';

export interface ITeamData {
  id: string;
  name: string;
  position: string;
  email: string;
  avatarImage: string;
}

function createData(
  id: string,
  name: string,
  position: string,
  email: string,
  avatarImage: string
): ITeamData {
  return {
    id,
    avatarImage,
    name,
    position,
    email
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof ITeamData;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome'
  },
  {
    id: 'position',
    numeric: false,
    disablePadding: true,
    label: 'Posizione'
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'E-mail'
  }
];
export const selectedTeamMatesAtom = atom<RWMember | undefined>(undefined);
export const teamMatesTablePaginationAtom = atom<{
  limit?: number;
  offset?: number;
}>({
  limit: 7,
  offset: 0
});
export const teamMatesTablePageNumberAtom = atom(0);
export type FilterFields = { roles?: RWMemberUpdatePayloadType['roles'] };
export const searchVectorAtom = atom('');
export const filtersAtom = atom<FilterFields>({});
export const fetchRWMemberQueryKeyAtom = atom((get) => [
  `rwMembers_${JSON.stringify(Object.values(get(teamMatesTablePaginationAtom)))}_${get(
    searchVectorAtom
  )}_${JSON.stringify(Object.values(get(filtersAtom)))}`
]);
fetchRWMemberQueryKeyAtom.debugLabel = 'fetchRWMemberQueryKeyAtom';
interface TeamMatesTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ITeamData) => void;
  order: Order;
  orderBy: string;
}
export default function TeamMatesTable() {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof ITeamData>('name');
  const [page, setPage] = useAtom(teamMatesTablePageNumberAtom);
  const [pagination, setPagination] = useAtom(teamMatesTablePaginationAtom);
  const [visible, setVisible] = useAtom(showDialogAtom);

  const handleRequestSort = React.useCallback(
    (_: React.MouseEvent<unknown>, property: keyof ITeamData) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleChangePage = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      setPage(page);
      setPagination((prev) => ({ ...prev, offset: prev.limit ? page * prev.limit : 0 }));
    },
    [setPage, setPagination]
  );

  const { data, isLoading } = useRWMembers();

  const totalMembers = React.useMemo(() => data?.hits, [data?.hits]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%' }}>
        <TeamMatesTableToolbar />
        <TableContainer>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="team-mates-table"
            size="small"
          >
            <TeamMatesTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TeamMatesTableBody orderBy={orderBy} order={order} rmMembersList={data?.data} />
          </Table>
        </TableContainer>
        <Backdrop invisible open={isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
        <TablePagination
          rowsPerPageOptions={[7, 50, 100]}
          onRowsPerPageChange={(evt) => {
            setPagination({ limit: parseInt(evt.target.value, 10), offset: 0 });
            setPage(0);
          }}
          component="div"
          count={totalMembers ?? 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={pagination.limit ?? 0}
        />
        <TeamMatesOptionMenu />
        <TeamMatesEditFormDialog
          visible={visible.visible && (visible.id === 'edit' || visible.id === 'create')}
          toggleVisibility={() =>
            setVisible({
              visible: false,
              id: ''
            })
          }
          key={visible.id}
        />
        <TeamMatesDeleteConfirmationDialog
          open={visible.visible && visible.id === 'delete'}
          setOpen={() =>
            setVisible({
              visible: false,
              id: ''
            })
          }
        />
      </Paper>
    </Box>
  );
}

function TeamMatesTableHead(props: TeamMatesTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof ITeamData) => (event: React.MouseEvent<unknown>) =>
    onRequestSort(event, property);
  const isAdmin = useAdminRole();

  return (
    <TableHead>
      <TableRow>
        <TableCell>Immagine</TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Ruoli</TableCell>
        {isAdmin && <TableCell>Azione</TableCell>}
      </TableRow>
    </TableHead>
  );
}

function TeamMatesTableToolbar() {
  const { handleToggle } = React.useContext(SidebarContext);
  const [, setVisible] = useAtom(showDialogAtom);
  const isAdmin = useAdminRole();

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 }
        }}
      >
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          Membri del team
        </Typography>
        {isAdmin && (
          <LoadingButton
            onClick={() => {
              setVisible({
                visible: true,
                id: 'create'
              });
            }}
            size="small"
            color="primary"
            variant="outlined"
            sx={{ width: 200, mr: 1 }}
          >
            + Aggiungi membro
          </LoadingButton>
        )}
        <Tooltip title="Elenco filtri">
          <IconButton
            onClick={() => handleToggle('filter', SIDEBAR_FILTERS_POSITION, SIDEBAR_FILTERS_WIDTH)}
          >
            <FilterList />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </>
  );
}

const TeamMatesTableBody = ({
  order,
  orderBy,
  rmMembersList
}: {
  order: Order;
  orderBy: keyof ITeamData;
  rmMembersList?: RWMember[];
}) => {
  const restructureData = React.useMemo(
    () =>
      rmMembersList?.map((member) =>
        createData(
          member.user_id,
          `${member.name} ${member.surname}`,
          member.position ?? '',
          member.user?.email ?? '',
          member.icon_url ?? ''
        )
      ),
    [rmMembersList]
  );
  const visibleRows = React.useMemo(
    () =>
      restructureData?.length ? stableSort(restructureData, getComparator(order, orderBy)) : [],
    [order, orderBy, restructureData]
  );
  const isAdmin = useAdminRole();

  return (
    <TableBody>
      {visibleRows.length ? (
        visibleRows.map((row) => {
          const teamData = rmMembersList?.find((member) => member.user_id === row.id);
          return (
            <TableRow tabIndex={-1} key={row.id}>
              <TableCell scope="row" component="th">
                <Avatar alt={row.name} src={row.avatarImage} />
              </TableCell>
              <TableCell scope="row" component="th">
                {row.name}
              </TableCell>
              <TableCell align="left" scope="row" component="th">
                {row.position}
              </TableCell>
              <TableCell align="left" scope="row" component="th">
                {row.email}
              </TableCell>
              <TableCell
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  flexWrap: 'wrap'
                }}
                align="left"
                scope="row"
                component="th"
              >
                {teamData?.roles?.map((role) => (
                  <Chip key={role.label} label={role.label} size="small" />
                ))}
              </TableCell>
              {isAdmin && (
                <TableCell align="left" scope="row" component="th">
                  <TeamMatesOptions teamData={teamData} />
                </TableCell>
              )}
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={5} align="center">
            <Typography variant="subtitle1" gutterBottom align="center">
              Nessun membro del team trovato
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export const useRWMembers = () => {
  const rwMembersService = useRWMemberService();
  const [pagination] = useAtom(teamMatesTablePaginationAtom);
  const fetchRWMemberQueryKey = useAtomValue(fetchRWMemberQueryKeyAtom);
  const searchVector = useAtomValue(searchVectorAtom);
  const filters = useAtomValue(filtersAtom);

  const fetchRWMembers = React.useCallback(
    async () =>
      (
        await rwMembersService.fetchRWMembers({
          ...(Object.keys(pagination).length ? { ...pagination } : {}),
          ...(searchVector ? { searchVector } : {}),
          ...(Object.keys(filters).length ? { ...filters } : {})
        })
      ).data,
    [filters, pagination, rwMembersService, searchVector]
  );

  return useQuery({
    queryKey: fetchRWMemberQueryKey,
    queryFn: fetchRWMembers,
    placeholderData: (previousData) => previousData,
    refetchOnWindowFocus: false
  });
};
export const useAdminRole = () => {
  const { userRoleKeys } = useAuth();
  return React.useMemo(() => userRoleKeys?.includes('admin'), [userRoleKeys]);
};
