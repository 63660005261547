import {
  EntityId,
  JobPosition,
  JPClosingMotivation,
  JobPositionTask
} from '@restworld/utility-types';
import { ENDPOINTS } from 'constants/api';
import { Service } from 'service/baseService';
import dynamicAxios from 'utils/axios';
import { useMemo } from 'react';
import useAuth from 'hooks/useAuth';
import { ListJobPositions } from '@restworld/data-services';
import { Id } from '@restworld/data-services/src/lib/utils/common_types';

export type JobPositionAssessmentQuestion = {
  id: number;
  job_position_id: string;
  job_position?: JobPosition;
  question?: string;

  inserted_at: string;
  updated_at: string;
};

export type JobPositionUpdated = JobPosition & {
  preassessment_questions?: JobPositionAssessmentQuestion[];
  manage_using_klaaryo?: boolean;
  advertised?: boolean;
  hirematic_flag?: boolean;
};

export interface JobPositionFilterType {
  job_specialization?: string;
  tam?: string;
  csm?: string;
  status_key?: string[];
  'advance_payment_paid?'?: boolean;
  priority?: number[];
  search_vector?: string;
  my_work?: boolean;
  status?: 'active';
}

class ServiceJobPositions extends Service {
  fetchJobPositions({
    restaurantId,
    limit,
    offset,
    order_by,
    filters
  }: {
    restaurantId?: string;
    limit?: number;
    offset?: number;
    order_by?: Array<string>;
    filters?: JobPositionFilterType;
  }) {
    const appliedFilters = filters || {};
    return dynamicAxios(this.jwt).get(ENDPOINTS.job_positions.root, {
      params: { limit, offset, restaurant_id: restaurantId, ...appliedFilters, order_by }
    });
  }

  listJobPositions({
    limit,
    offset,
    order_by,
    employer_id,
    search_vector,
    organization_id
  }: {
    limit: number;
    offset: number;
    order_by?: Array<string>;
    employer_id?: string;
    search_vector?: string;
    organization_id?: string;
  }) {
    return dynamicAxios(this.jwt).post<ListJobPositions>(ENDPOINTS.employers.graphql, {
      query: indexJobPositionsQuery,
      variables: { limit, offset, order_by, employer_id, search_vector, organization_id }
    });
  }

  duplicate(jobPositionId: EntityId, restaurantId: EntityId, params?: Partial<JobPosition>) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.job_positions.duplicate, {
      restaurant_id: restaurantId,
      job_position_id: jobPositionId,
      ...(params || {})
    });
  }

  showJobPosition(jobPositionId: EntityId) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.job_positions.root}/${jobPositionId}`);
  }

  showJobPositionStats(jobPositionId: EntityId) {
    return dynamicAxios(this.jwt).get(`${ENDPOINTS.job_positions.stats}`, {
      params: {
        job_position_id: jobPositionId
      }
    });
  }

  updateJobPosition(jobPositionId: EntityId, params: any) {
    return dynamicAxios(this.jwt).put(`${ENDPOINTS.job_positions.root}/${jobPositionId}`, params);
  }

  updateJobPositionStatus(
    jobPositionId: EntityId,
    newStatusKey: string,
    params?: Partial<JPClosingMotivation>
  ) {
    return dynamicAxios(this.jwt).patch(`${ENDPOINTS.job_positions.status}/${jobPositionId}`, {
      status_key: newStatusKey,
      ...params
    });
  }

  assignTam(userId: EntityId, jobPositionId: EntityId) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.job_positions.assign_tam, {
      talent_acquisition_manager_id: userId,
      job_position_id: jobPositionId
    });
  }

  makeTamMain(userId: EntityId, jobPositionId: EntityId) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.job_positions.make_tam_main, {
      talent_acquisition_manager_id: userId,
      job_position_id: jobPositionId
    });
  }

  removeTam(userId: EntityId, jobPositionId: EntityId) {
    return dynamicAxios(this.jwt).delete(ENDPOINTS.job_positions.remove_tam, {
      data: {
        talent_acquisition_manager_id: userId,
        job_position_id: jobPositionId
      }
    });
  }

  assignCsm(userId: EntityId, jobPositionId: EntityId) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.job_positions.assign_csm, {
      customer_success_manager_id: userId,
      job_position_id: jobPositionId
    });
  }

  makeCsmMain(userId: EntityId, jobPositionId: EntityId) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.job_positions.make_csm_main, {
      customer_success_manager_id: userId,
      job_position_id: jobPositionId
    });
  }

  removeCsm(userId: EntityId, jobPositionId: EntityId) {
    return dynamicAxios(this.jwt).delete(ENDPOINTS.job_positions.remove_csm, {
      data: {
        customer_success_manager_id: userId,
        job_position_id: jobPositionId
      }
    });
  }

  addTask(jobPositionId: EntityId, task: JobPositionTask) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.job_positions.task, {
      job_position_id: jobPositionId,
      due_date: task.due_date,
      category: task.category || task.category_var?.id,
      sub_category: task.sub_category || task.sub_category_var?.id,
      description: task.description,
      worker_ids: task.worker_ids
    });
  }

  updateTask(taskId: number, params: Partial<JobPositionTask>) {
    return dynamicAxios(this.jwt).patch(ENDPOINTS.job_positions.task, {
      id: taskId,
      ...params,
      category: params.category_var?.id || params.category,
      sub_category: params.sub_category_var?.id || params.sub_category
    });
  }

  deleteTask(taskId: number) {
    return dynamicAxios(this.jwt).delete(ENDPOINTS.job_positions.task, { data: { id: taskId } });
  }

  getTask(taskId: number) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.job_positions.task, { params: { id: taskId } });
  }

  getJobPositionApplicationPerformanceMetrics(jobPositionId: EntityId) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.job_positions.application_performance_metrics, {
      params: { job_position_id: jobPositionId }
    });
  }

  createJobPosition({ params }: { params: Partial<JobPosition> }) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.job_positions.root, {
      ...params
    });
  }

  assignJuniorRecruiter({
    jobPositionId,
    juniorRecruiterId
  }: {
    jobPositionId?: string;
    juniorRecruiterId?: string;
  }) {
    return dynamicAxios(this.jwt).post<string>(
      `${ENDPOINTS.job_positions.root}/assign_junior_recruiter`,
      {
        job_position_id: jobPositionId,
        junior_recruiter_id: juniorRecruiterId
      }
    );
  }

  requestJuniorRecruiter({
    jobPositionId,
    requestedJr
  }: {
    jobPositionId?: string;
    requestedJr?: boolean;
  }) {
    return dynamicAxios(this.jwt).post<string>(
      `${ENDPOINTS.job_positions.root}/request_assistance`,
      {
        job_position_id: jobPositionId,
        requested_jr: requestedJr
      }
    );
  }

  setSeoData({
    job_position_id,
    meta_background_image,
    meta_image,
    meta_image_text_tag_1,
    meta_image_text_tag_2,
    meta_image_text_tag_3,
    meta_description,
    meta_title
  }: {
    job_position_id: string;
    meta_background_image?: File;
    meta_image?: File;
    meta_image_text_tag_1?: string;
    meta_image_text_tag_2?: string;
    meta_image_text_tag_3?: string;
    meta_description?: string;
    meta_title?: string;
  }) {
    const formData = new FormData();
    formData.append('id', job_position_id);
    if (meta_background_image) formData.append('meta_background_image', meta_background_image);
    if (meta_image) formData.append('meta_image', meta_image);
    if (meta_image_text_tag_1) formData.append('meta_image_text_tag_1', meta_image_text_tag_1);
    if (meta_image_text_tag_2) formData.append('meta_image_text_tag_2', meta_image_text_tag_2);
    if (meta_image_text_tag_3) formData.append('meta_image_text_tag_3', meta_image_text_tag_3);
    if (meta_description) formData.append('meta_description', meta_description);
    if (meta_title) formData.append('meta_title', meta_title);

    return dynamicAxios(this.jwt).post<JobPosition>(
      `${ENDPOINTS.job_positions.root}/seo_metadata`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    );
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceJobPositions(jwt), [jwt]);
};
export default useService;

export const indexJobPositionsQuery = `
    query index_job_positions(
        $limit: Int!,
        $offset: Int!,
        $order_by: [String],
        $employer_id: String,
        $search_vector: String,
        $organization_id: String
    ) {
        index_job_positions(
            limit: $limit,
            offset: $offset,
            order_by: $order_by,
            employer_id: $employer_id,
            search_vector: $search_vector,
            organization_id: $organization_id
        ) {
            hits
            data {
                csm_list {
                    user_id,
                    icon_url,
                    name,
                    surname
                },
                number_of_interested_workers,
                number_of_active_shortlisted_workers,
                number_of_discarded_shortlisted_workers,
                number_of_hired_workers,
                job_position {
                    id,
                    published_at,
                    inserted_at,
                    job_title,
                    semantic_unique_name,
                    requested_jr,
                    occupation {
                        label_it {
                            label
                        }
                    },
                    next_task {
                        id,
                        due_date,
                        inserted_at
                    },
                    restaurant {
                        id,
                        employer_id,
                        restaurant_name,
                        organization_id
                    },
                    status_key,
                    status {
                      id,
                      key,
                      label,
                      level,
                      description,
                      sentiment
                  },
                  tasks {
                    id,
                    job_position_id,
                    due_date,
                    category,
                    sub_category,
                    description,
                    completed,
                    worker_ids,
                    rw_member {
                      user_id,
                      icon_url,
                      name,
                      surname,
                      roles {
                        id,
                        label,
                        key
                      }
                    },
                    sub_category_var {
                      label
                    },
                    category_var {
                      label
                    },
                    inserted_at
                  }
                }
            }
        }
    }
`;
