// MUI
import { Box, CircularProgress, IconButton, useTheme } from '@mui/material';

// Custom components
import Image from 'components/Image';

// Icons
import PhotoCamera from '@mui/icons-material/PhotoCamera';

// Types
import { ContextStatus } from '../../@types/componentLifecycle';
import { EntityId } from '@restworld/utility-types';

interface Props {
  status?: ContextStatus;
  labelId?: string;
  entityId?: EntityId;
  handleUpload?: (e: any, entityId: EntityId) => void;
  customAction?: VoidFunction;
}
export default function NoPicture({
  status,
  labelId = 'upload-picture',
  entityId,
  handleUpload,
  customAction
}: Props) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        border: 'dashed 1px',
        borderColor: theme.palette.grey[500],
        borderRadius: '16px'
      }}
    >
      <Image ratio="1/1" placeholderType="white" />
      {!!customAction && (
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '100px',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
          }}
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            size="large"
            onClick={customAction}
          >
            <PhotoCamera fontSize="large" />
          </IconButton>
        </Box>
      )}
      {!!entityId && !!handleUpload && (
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: { lg: '60px', md: '30px', sm: '100px', xs: '80px' },
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
          }}
        >
          {status?.status === 'LOADING' ? (
            <CircularProgress thickness={1.6} />
          ) : (
            <label htmlFor={labelId}>
              <input
                style={{ display: 'none' }}
                accept="image/*"
                id={labelId}
                onChange={(e: any) => handleUpload(e, entityId)}
                type="file"
              />
              <IconButton color="primary" aria-label="upload picture" component="span" size="large">
                <PhotoCamera fontSize="large" />
              </IconButton>
            </label>
          )}
        </Box>
      )}
    </Box>
  );
}
