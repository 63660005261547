import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { RWMemberUpdatePayloadType } from 'service/rw_members';
import { useAtomValue } from 'jotai';
import { useRwMembersService } from '@restworld/data-services';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RWMember, RWMemberRoleKeys } from '@restworld/utility-types';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';

import { fetchRWMemberQueryKeyAtom, selectedTeamMatesAtom } from './TeamMatesTable';
import TeamMemberRolesSelector from './TeamMemberRolesSelector';
import { showDialogAtom } from './TeamMatesOptions';
import TeamMatePicture from './TeamMatePicture';

const defaultValues = {
  name: '',
  surname: '',
  email: '',
  position: '',
  roles: [],
  password: '',
  confirm_password: ''
};
export default function TeamMatesEditFormDialog({
  visible,
  toggleVisibility
}: {
  visible: boolean;
  toggleVisibility: () => void;
}) {
  const visibleData = useAtomValue(showDialogAtom);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const selectedTeamMates = useAtomValue(selectedTeamMatesAtom);
  const rwServices = useRwMembersService();
  const queryKey = useAtomValue(fetchRWMemberQueryKeyAtom);
  const isCreateUser = React.useMemo(() => visibleData.id === 'create', [visibleData.id]);

  const formSchema = Yup.object().shape({
    name: Yup.string().required('Il nome è obbligatorio!'),
    surname: Yup.string().required('Il cognome è obbligatorio!'),
    email: Yup.string().email('E-mail non valida!').required("L'e-mail è obbligatoria!"),
    position: Yup.string().required('La posizione è richiesta!'),
    password: Yup.string()
      .min(6, 'La lunghezza della password deve essere di almeno 6 caratteri')
      .max(12, 'La password non può superare più di 12 caratteri'),
    confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Le password non corrispondono')
  });
  const methods = useForm<RWMemberUpdatePayloadType>({
    defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(formSchema)
  });
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    watch,
    setError
  } = methods;
  const password = watch('password');
  const createUser = React.useCallback(
    async (data: RWMemberUpdatePayloadType) => {
      try {
        setLoading(true);
        await rwServices.createRwMember(data);
        queryClient.invalidateQueries({
          queryKey
        });
        toggleVisibility();
        enqueueSnackbar('Utente creato con successo', { variant: 'success' });
      } catch (error) {
        const errorData = error as {
          data: {
            errors: {
              [key: string]: string[] | undefined;
            };
          };
        };
        const errorText = "Errore durante la creazione dell'utente";
        console.error(`${errorText}:`, errorData.data.errors);
        Object.keys(errorData.data.errors).forEach((key) => {
          setError(key as keyof typeof defaultValues, {
            type: 'manual',
            message: errorData.data.errors[key]?.[0]
          });
        });
        enqueueSnackbar(errorText, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, queryClient, queryKey, rwServices, setError, toggleVisibility]
  );

  const updateUser = React.useCallback(
    async (data: RWMemberUpdatePayloadType) => {
      if (!selectedTeamMates?.user_id) return;
      try {
        setLoading(true);
        await rwServices.updateRwMember(selectedTeamMates?.user_id, {
          ...data
        });
        queryClient.invalidateQueries({
          queryKey
        });
        toggleVisibility();
        enqueueSnackbar('Utente aggiornato con successo', { variant: 'success' });
      } catch (error) {
        const errorData = error as {
          data: {
            errors: {
              [key: string]: string[] | undefined;
            };
          };
        };
        const errorText = "Errore durante l'aggiornamento dell'utente";
        console.error(`${errorText}:`, errorData.data.errors);
        Object.keys(errorData.data.errors).forEach((key) => {
          setError(key as keyof typeof defaultValues, {
            type: 'manual',
            message: errorData.data.errors[key]?.[0]
          });
        });
        enqueueSnackbar(errorText, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      queryClient,
      queryKey,
      rwServices,
      selectedTeamMates?.user_id,
      setError,
      toggleVisibility
    ]
  );

  const onsubmit = React.useCallback(
    async (data: RWMemberUpdatePayloadType) => {
      if (isCreateUser) {
        createUser(data);
        return;
      }
      updateUser(data);
    },
    [createUser, isCreateUser, updateUser]
  );

  React.useEffect(() => {
    password && trigger('confirm_password');
  }, [password, trigger]);

  React.useEffect(() => {
    if (isCreateUser) reset(defaultValues);
    else if (selectedTeamMates)
      reset({
        ...{
          ...selectedTeamMates,
          email: selectedTeamMates.user?.email ?? '',
          roles: (selectedTeamMates.roles?.map((role) => role.key) as RWMemberRoleKeys[]) ?? []
        }
      });
  }, [isCreateUser, reset, selectedTeamMates]);

  return (
    <Dialog open={visible} onClose={() => toggleVisibility()}>
      <DialogTitle>
        {isCreateUser ? 'Crea un nuovo membro del team' : 'Aggiorna utente'}
      </DialogTitle>
      <FormProvider {...methods}>
        <form action="" method="post" onSubmit={handleSubmit(onsubmit)}>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
              {isCreateUser
                ? 'Aggiungi i dettagli del nuovo utente qui sotto.'
                : "Aggiorna i dettagli dell'utente di seguito."}
            </DialogContentText>
            {!!selectedTeamMates && (
              <TeamMatePicture
                teamMate={selectedTeamMates as unknown as RWMember & { picture: string }}
              />
            )}
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  fullWidth
                  // variant="standard"
                  label="Nome"
                  placeholder="Inserisci il nome"
                  error={!!errors.name}
                  helperText={errors.name?.message ?? ''}
                  {...field}
                />
              )}
              name="name"
            />
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  margin="dense"
                  id="surname"
                  fullWidth
                  // variant="standard"
                  label="Cognome"
                  placeholder="Inserisci il cognome"
                  error={!!errors.surname}
                  helperText={errors.surname?.message ?? ''}
                  {...field}
                />
              )}
              name="surname"
            />
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  margin="dense"
                  id="email"
                  fullWidth
                  // variant="standard"
                  label="E-mail"
                  placeholder="Inserisci l'e-mail"
                  error={!!errors.email}
                  helperText={errors.email?.message ?? ''}
                  type="email"
                  {...field}
                />
              )}
              name="email"
            />
            <Controller
              control={control}
              name="slack_id"
              render={({ field }) => (
                <TextField
                  margin="dense"
                  id="slack_id"
                  fullWidth
                  label="Slack ID"
                  placeholder="Inserisci l'ID di Slack"
                  error={!!errors?.slack_id}
                  helperText={errors?.slack_id?.message ?? ''}
                  type="text"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="intercom_user_id"
              render={({ field }) => (
                <TextField
                  margin="dense"
                  id="intercom_admin_id"
                  fullWidth
                  label="Intercom ID"
                  placeholder="Inserisci l'ID di Intercom"
                  error={!!errors?.intercom_user_id}
                  helperText={errors?.intercom_user_id?.message ?? ''}
                  type="text"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  margin="dense"
                  id="position"
                  fullWidth
                  // variant="standard"
                  label="Posizione"
                  placeholder="Inserisci la posizione"
                  error={!!errors.position}
                  helperText={errors.position?.message ?? ''}
                  type="position"
                  {...field}
                />
              )}
              name="position"
            />
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  required={isCreateUser}
                  margin="dense"
                  id="password"
                  fullWidth
                  // variant="standard"
                  label="Password"
                  placeholder="*************"
                  error={!!errors.password}
                  helperText={errors.password?.message ?? ''}
                  type="password"
                  {...field}
                />
              )}
              name="password"
            />
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  required={isCreateUser}
                  margin="dense"
                  id="confirm_password"
                  fullWidth
                  // variant="standard"
                  label="Conferma password"
                  placeholder="*************"
                  error={!!errors.confirm_password}
                  helperText={errors.confirm_password?.message ?? ''}
                  type="password"
                  {...field}
                />
              )}
              name="confirm_password"
            />
            <TeamMemberRolesSelector />
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={() => toggleVisibility()}>
              Annulla
            </Button>
            <LoadingButton variant="contained" color="primary" loading={loading} type="submit">
              Salva
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
