import { useMemo } from 'react';
import dynamicAxios from 'utils/axios';
import { Service } from './baseService';
import { ENDPOINTS } from 'constants/api';
import useAuth from 'hooks/useAuth';
import { Id } from '@restworld/data-services/src/lib/utils/common_types';
import { Company, Organization, RWMember } from '@restworld/utility-types';
import {
  EmployerFilterType,
  // IndexStripePaymentMethods,
  IndexStripeSubscriptions,
  StripePaymentMethod
} from '@restworld/data-services';
import {
  CreateOrganization,
  IndexOrganizations
} from '@restworld/data-services/src/lib/services/useOrganizations/types';

export type IndexStripePaymentMethods = {
  company_id: string;
  payment_methods: {
    type: StripePaymentMethod;
    brand?: string;
    last4?: string;
    billing_details: {
      address: string;
      email: string;
      name: string;
      phone: string;
    };
  }[];
}[];

export type OrgnaizationStructuredNote = {
  organization_id: string;
  organization?: Organization;

  restaurant_name?: string;
  city?: string;
  number_of_flex_slots_to_purchase?: number;
  number_of_restaurants?: number;
  number_of_collaborators?: number;
  client_relevance?: string;
  forecasted_hiring_in_current_year?: number;
  past_experience_in_hr_recruiting?: string;

  referent?: string;
  business_type?: string;
  website?: string;
  province?: string;
  country?: string;
  referent_attitude?: string;
  free_notes?: string;

  last_modified_by?: string;
  last_modified_by_rw_member?: RWMember;
  purchased_products?: string[];
  job_roles?: {
    occupation?: string;
    number_of_people_required?: number;
    contract_and_working_hours?: string;
    salary_range?: string;
    seasonal?: boolean;
    accommodation?: boolean;
  }[];

  inserted_at: string;
  updated_at: string;
};

type IndexCompanies = {
  data: Company[];
  hits: number;
};

class ServiceAccounts extends Service {
  indexOrganizations({
    limit,
    offset,
    order_by,
    filters
  }: {
    limit: number;
    offset: number;
    order_by?: string[];
    filters?: EmployerFilterType;
  }) {
    return dynamicAxios(this.jwt).get<IndexOrganizations>(ENDPOINTS.organizations.root, {
      params: { limit, offset, order_by, ...filters }
    });
  }

  createOrganization(data: Partial<Organization>) {
    return dynamicAxios(this.jwt).post<CreateOrganization>(ENDPOINTS.organizations.root, data);
  }

  updateOrganization(data: Partial<Organization>) {
    return dynamicAxios(this.jwt).patch<Id>(`${ENDPOINTS.organizations.root}/${data.id}`, data);
  }

  showOrganization(organizationId: string, context: string) {
    return dynamicAxios(this.jwt).get<Organization>(
      `${ENDPOINTS.organizations.root}/${organizationId}`,
      { params: { context } }
    );
  }

  listCompanies({
    limit,
    offset,
    organizationId,
    context
  }: {
    context: string;
    limit: number;
    offset: number;
    organizationId: string;
  }) {
    return dynamicAxios(this.jwt).get<IndexCompanies>(ENDPOINTS.companies.root, {
      params: { organization_id: organizationId, limit, offset, context }
    });
  }

  createCompany(data: Partial<Company>) {
    return dynamicAxios(this.jwt).post<Id>(ENDPOINTS.companies.root, data);
  }

  updateCompany(data: Partial<Company>) {
    return dynamicAxios(this.jwt).patch<Id>(`${ENDPOINTS.companies.root}/${data.id}`, data);
  }

  fetchStripeSubscriptions({
    companyId,
    organizationId
  }: {
    companyId?: string;
    organizationId?: string;
  }) {
    return dynamicAxios(this.jwt).get<IndexStripeSubscriptions>(ENDPOINTS.companies.subscriptions, {
      params: { company_id: companyId, organization_id: organizationId }
    });
  }

  fetchStripePaymentMethods({
    organizationId,
    companyId
  }: {
    organizationId?: string;
    companyId?: string;
  }) {
    return dynamicAxios(this.jwt).get<IndexStripePaymentMethods>(
      ENDPOINTS.organizations.paymentMethods,
      {
        params: { organization_id: organizationId, company_id: companyId }
      }
    );
  }

  sendSubscriptionEmail({ companyId, paymentLink }: { companyId?: string; paymentLink?: string }) {
    return dynamicAxios(this.jwt).post<string>(ENDPOINTS.companies.subscriptionEmail, {
      company_id: companyId,
      payment_link: paymentLink
    });
  }

  deleteCompany(companyId?: string) {
    return dynamicAxios(this.jwt).delete<string>(`${ENDPOINTS.companies.root}/${companyId}`);
  }

  createOrganizationStructuredNote(note: Partial<OrgnaizationStructuredNote>) {
    return dynamicAxios(this.jwt).post<string>(ENDPOINTS.organizations.structuredNotes, note);
  }

  updateOrganizationStructuredNote(note: Partial<OrgnaizationStructuredNote>) {
    return dynamicAxios(this.jwt).patch<string>(
      `${ENDPOINTS.organizations.structuredNotes}/${note.organization_id}`,
      note
    );
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceAccounts(jwt), [jwt]);
};
export default useService;
